import { Button } from "../Button";
import { FeaturesWrapper, FeatureHighlights, FeatureHighlight, FeatureHighlightTitle, FeatureHighlightSubtitle, FeatureHighlightLink, FeatureHighlightImage, FeatureHighlightContent, FeaturesCards, FeaturesCardTitle, FeaturesCard, FeaturesCardDescription, FeatureTitle, FeatureHighlightVideo } from "./NetworkFeatures";
import { HeroWrapper, HeroTitle, HeroSubtitle, HeroContentFull, HeroCtas, HeroCTA } from "./Hero";
import Head from "next/head";
import { OurApproachTitle, OurApproachWrapper } from "./OurApproach";
import { RoadMapCard, RoadMapCardDescription, RoadMapCardSubtitle, RoadMapCardTitle, RoadMapCards, RoadMapLine, RoadMapTitle, RoadMapWrapper } from "./RoadMap";
import { GradientDivider, GradientDividerLeft, GradientDividerTop } from "../GradientDivider";
import { SocialProofTicker, SocialProofWrapperComponent } from "./SocialProof";
import ParallaxHero from "../ParallaxHero";
import { useEffect } from "react";
import { isProduction } from "../helpers";
import { Flex } from "@radix-ui/themes";
import { NumberCard, NumberCardTitle, NumberContent, NumberText, NumberWrapper } from "./Number";

export const networkTitle = "The Covalent Network | Covalent";

export const NetworkHomePage = ({ content, ecosystem }) => {
    const getSection = (slug) => {
        return content.content.filter((section) => section.section === slug)[0];
    };
    const hero = getSection("hero");
    const benefits = getSection("benefits");
    const features = getSection("features");
    const ourApproach = getSection("our-approach");
    const roadMap = getSection("roadmap");
    const numbers = getSection("numbers");
    const PREVIEW = process.env.NEXT_PUBLIC_IS_PREVIEW;
    useEffect(() => {
        if (isProduction && !PREVIEW) {
            const host = window.location.host;
            if (host !== "covalentnetwork.org") {
                window.location.replace("https://covalentnetwork.org/");
            }
        }
    }, []);

    return <>
        <Head>
            <title>{networkTitle}</title>
            <meta name="description" content={content.meta.description} />
            <meta name="keywords" content={content.meta.keywords} />
            <meta property="og:title" content={content.meta.title} />
            <meta property="og:description" content={content.meta.description} />
            <meta property="og:title" content={`${content.meta.title} | Covalent`} />
            <meta name="og:description" content={content.meta.description} />
            <meta name="og:image" content={`https://og-generator-liart.vercel.app/api/param?title=${encodeURI((content.meta.title))}`} />
            <link
                rel="canonical"
                href={`https://covalentnetwork.org/`}
                key="canonical" />
        </Head>
        <main>
            <div className="network-page">
                <ParallaxHero dark imageUrl={"var(--covalent-dark-blue)"} video={"https://www.datocms-assets.com/86369/1709079919-eth_loop_low.webm"} videoAlt="https://www.datocms-assets.com/86369/1705950304-eth_loop_low.mp4">
                    <HeroWrapper dark size="md">
                        <GradientDividerLeft />
                        <GradientDivider height={240}/>
                        <HeroContentFull>
                            <HeroTitle>
                                {hero.title}
                            </HeroTitle>
                            <HeroSubtitle>
                                {hero.subtitle}
                            </HeroSubtitle>
                            <HeroCtas>
                                <HeroCTA>
                                    <Button teal href="https://www.covalenthq.com/docs/covalent-network/resources/ethereum-wayback-machine/">Read the paper</Button>
                                </HeroCTA>
                            </HeroCtas>
                        </HeroContentFull>
                    </HeroWrapper>
                    <FeaturesWrapper dark>
                        <FeatureHighlights>
                            {benefits.list.map((build, i) => <FeatureHighlight key={i}>
                                <FeatureHighlightContent small={(build.video && true)}>
                                    <FeatureHighlightTitle>
                                        {build.title}
                                    </FeatureHighlightTitle>
                                    <FeatureHighlightSubtitle>
                                        {build.description}
                                    </FeatureHighlightSubtitle>
                                    {((build.cta && build.cta.link) && <FeatureHighlightLink>
                                        <Flex gap="3">
                                            <Button teal target="_blank" href={hero.ctas[0].link}>{hero.ctas[0].text}</Button>
                                            <Button teal secondary href={build.cta.link}>
                                                {build.cta.text}
                                            </Button>
                                        </Flex>
                                    </FeatureHighlightLink>)}
                                </FeatureHighlightContent>
                                {(build.image && <FeatureHighlightImage src={build.image} />)}
                                {(build.video && <FeatureHighlightVideo src={build.video} />)}
                            </FeatureHighlight>)}
                        </FeatureHighlights>
                    </FeaturesWrapper>
                    <NumberWrapper dark>
                        <GradientDivider />
                        <GradientDividerTop />
                        <NumberContent>
                            <div className="number-side">
                                {
                                    numbers.list.map((number) => {
                                        return <NumberCard key={number.title}>
                                            <NumberText teal>
                                                <h2>{number.value}</h2>
                                                <NumberCardTitle>
                                                    {number.title}
                                                </NumberCardTitle>
                                            </NumberText>
                                        </NumberCard>;
                                    })
                                }
                            </div>
                        </NumberContent>
                    </NumberWrapper>
                    <OurApproachWrapper dark>
                        <GradientDividerTop/>
                        <GradientDivider />
                        <OurApproachTitle>
                            {ourApproach.title}
                        </OurApproachTitle>
                        <div className="spacer"/>
                        <Flex className="globe-section" direction="column" align="center" width="100%" style={{backgroundImage: `url(${ourApproach.world})`}}>
                            <SocialProofWrapperComponent>
                                <SocialProofTicker ecosystem={ecosystem} />
                            </SocialProofWrapperComponent>
                        </Flex>
                    </OurApproachWrapper>
                    <FeaturesWrapper dark>
                        <FeatureTitle>{features.title}</FeatureTitle>
                        <FeaturesCards grid={3}>
                            {features.list.map((build, i) => <FeaturesCard key={i}>
                                <FeaturesCardTitle>
                                    {build.title}
                                </FeaturesCardTitle>
                                <FeaturesCardDescription>
                                    {build.description}
                                </FeaturesCardDescription>
                            </FeaturesCard>)}
                        </FeaturesCards>
                    </FeaturesWrapper>
                    <RoadMapWrapper dark>
                        <RoadMapTitle>
                            {roadMap.title}
                        </RoadMapTitle>
                        <RoadMapCards>
                            <RoadMapLine/>
                            {roadMap.list.map((roadmap) => {
                                return <RoadMapCard key={roadmap.title} active={roadmap.active}>
                                    <RoadMapCardSubtitle>
                                        {roadmap.subtitle}
                                    </RoadMapCardSubtitle>
                                    <RoadMapCardTitle>
                                        {roadmap.title}
                                    </RoadMapCardTitle>
                                    <RoadMapCardDescription>
                                        {roadmap.description}
                                    </RoadMapCardDescription>
                                </RoadMapCard>;
                            })}
                        </RoadMapCards>
                    </RoadMapWrapper>
                </ParallaxHero>
                <style jsx>{`
            .network-page{
                overflow:hidden;
            }
            .pink-icon {
                color: var(--pink);
                height: 80px;
            }
            .big-icon {
                font-size: 80px;
                line-height: 1;
            }
            .spacer {
                margin-bottom: 1rem;
            }
            .max-w-text{
                max-width: var(--text-max-width-2);
            }
            .top-wrapper {
                position: relative;
            }
        `}</style>
            </div>
        </main>
    </>;
};