
import { GetAllDatoImages, api, convertNumberedKeysToArrays, replaceImageValues } from "../../components/helpers";
import Layout from "../../components/layouts/NetworkLayout";
import { NetworkHomePage } from "../../components/pages/NetworkHomePage";

const networkPageContent = require("../../data/network-page.json");

export default function Page({ content, ecosystem, mobileMenuIsOpen, setMobileMenu, user, busy, handleToast, isTransparent }) {
    return <Layout handleToast={handleToast} user={user} busy={busy} setMobileMenu={setMobileMenu} mobileMenuIsOpen={mobileMenuIsOpen} isTransparent={isTransparent}><NetworkHomePage content={content} ecosystem={ecosystem} /></Layout>;
}

export async function getStaticProps() {
    const ecosystem = await api.get_all_ecosystems();
    const content = networkPageContent.filter((page) => page.slug === "home")[0];
    const images = await GetAllDatoImages();
    const transformedContent = convertNumberedKeysToArrays(replaceImageValues(content, images));
    const ecosystemData = ecosystem.data.allEcosystems.filter((o) => o.product === "network").map((item) => item.imgwhite.responsiveImage);
    return {
        props: {
            component: "ProductPage",
            ecosystem: ecosystemData,
            content: transformedContent,
        },
    };
}