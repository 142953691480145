import Footer from "../NetworkFooter";
import { Titillium_Web } from "next/font/google";
import NetworkNav from "../NetworkNav";
import useAnimation from "../hooks/useAnimation";
import React from "react";
const tw = Titillium_Web({ weight: ["300", "400"], subsets: ["latin"] });


export default function Layout(props) {
    useAnimation();
    
    return (<React.Fragment><div className={`dark top-nav ${props.isTransparent ? "nav-transparent" : "nav-dark-blue"}`}>
        <div className="ready">
            <NetworkNav mobileMenuIsOpen={props.mobileMenuIsOpen} setMobileMenu={props.setMobileMenu}/>
        </div>
        {!props.isTransparent && <div className="divider-cont">
            <div className="nav-divider" />
        </div>}
    </div>

    <div id="main-wrapper" className={(props.mobileMenuIsOpen ? " menu-open " : "") + " page dark " + " main-wrapper "}>
        <main className="main">
            <div className="inner">      
                {props.children}
            </div>
        </main>
        <div className="footer-bar">
            <Footer handleToast={props.handleToast}/>
        </div>
    </div>
    <style jsx global>{`
            body{
                overflow:${props.mobileMenuIsOpen ? "hidden" : "auto"};
                overflow-x:hidden;
            }

            .nav-divider{
                border-top: 1px solid var(--black-medium);
                border-left:300px solid transparent;
                border-right:300px solid transparent;
                background: var(--covalent-dark-blue);
              }
            .divider-cont{
                overflow:hidden;
                width:100%;

            }
            .top-nav{
                position: fixed;
                width:100%;
                top:0;
                z-index:999;
                backdrop-filter: blur(10px);
                transition-property: all;
                transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                transition-duration: 150ms;
            }
            .nav-transparent{ 
                background:transparent;
            }

            .top-nav:hover{
                background: var(--background);
            }

            .main-wrapper {
                overflow-x:hidden;
            }
            .inner {
                max-width: 100vw;
                width: 100%;
            }
            th,
            td > strong {
                font-family: ${tw.style.fontFamily} , sans-serif;
            }
            
            .logo-title {
                font-family: ${tw.style.fontFamily}, sans-serif;
            }
            .date {
                font-size: var(--font-size-3);
                margin-top: -1rem;
                color: var(--pink);
            }
            `}</style>
    <style jsx>
        {`
          .page {
            justify-content:center;
            min-height: calc(100vh - var(--top-nav-height));
          }
          .page main {
            display:flex;
          }

          .page.menu-open {
            overflow: hidden;
            height: 100%;
          }

          .dark .page {
            border-bottom-color: var(--black-light);
          }

          .main{
           width: 100%;
          }

          article {
            padding: 2rem 1.5rem 3rem;
          }

          .footer-bar {
            flex: 1;
            padding: 0;
          }

        `}
    </style>
    </React.Fragment>
    );
}