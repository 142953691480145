import { useEffect } from "react";

const useAnimation = () => {
    useEffect(() => {
        const btn = document.querySelectorAll(".ripples");
        btn.forEach((btn) => {
            const el = btn as HTMLElement;
            el.addEventListener('click',function (e) {
                const target = e.currentTarget;
                if (target instanceof HTMLElement) {
                    const x = e.pageX - target.offsetLeft;
                    const y = e.pageY - target.offsetTop;
                    const ripples = document.createElement("span");
                    ripples.style["position"] = "absolute";
                    ripples.style["background"] = "#fff";
                    ripples.style["transform"] = "translate(-50%,-50%)";
                    ripples.style["pointer-events"] = "none";
                    ripples.style["border-radius"] = "50%";
                    ripples.style["animation"] = "ripple 2s linear infinite";
                    ripples.style["transition"] = "0.5s";
                    ripples.style.left = x + "px";
                    ripples.style.top = y + "px";
                    target.appendChild(ripples);

                    setTimeout(() => {
                        ripples.remove();
                    }, 2000);
                }
            });
        });
        
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                const target = entry.target as HTMLElement;
                if (entry.isIntersecting) {
                    target.style.transform = "";
                    target.style.opacity = "";
                } else {
                    target.style.transform = "translateY(2rem)";
                    target.style.opacity = "0";
                }
            });
        });

        const animateInElements = document.querySelectorAll('.animate-in');
        animateInElements.forEach(element => {
            const htmlElement = element as HTMLElement;
            observer.observe(htmlElement);
        });
    
        return () => {
            observer.disconnect();
        };
    }, []);
};

export default useAnimation;