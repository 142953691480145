import { Image } from "react-datocms";

export const RoadMapWrapper = ({children, dark = false}) => {
    return <>
        <div className={"roadmap " + (dark && "dark")}>
            <div className="roadmap__content">
                {children}
            </div>
        </div>
        <style jsx>
            {`
            .roadmap {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100vw;
                min-height: 50vh;
                height: max-content;
                background: var(--off-white);
            }
            .roadmap.dark {
                background-color: var(--covalent-dark-blue);
                color: #fff;
            }
            :global(.roadmap.dark .roadmap__cards) {
                margin-top: 0rem;
                padding: 0;
                position: relative;
            }
            :global(.roadmap.dark .roadmap__cards) {
                // flex-wrap: wrap;
                overflow-x: auto;
                padding-bottom: 2rem;
                overflow-y: auto;
                width: calc(100% + var(--landing-page-padding));
                padding-top: 3rem;
                padding-bottom: 5rem;
            }
            :global(.roadmap.dark .roadmap__cards::-webkit-scrollbar, .roadmap.dark .roadmap__cards::-webkit-scrollbar-track, .roadmap.dark .roadmap__cards::-webkit-scrollbar-thumb, .roadmap.dark .roadmap__cards::-moz-scrollbar, .roadmap.dark .roadmap__cards::-moz-scrollbar-track, .roadmap.dark .roadmap__cards::-moz-scrollbar-thumb) {
                display: none;
            }
            :global(.roadmap.dark .roadmap__cards) {
                scrollbar-width: none;
                -ms-overflow-style: none; /* IE 11 */
            }

            :global(.roadmap.dark .roadmap__card) {
                display: flex;
                flex-direction: column;
                justify-content: start;
                align-items: start;
                flex: 1 1 calc(20% - 1.5rem);
                min-width: 200px;
                max-width: 220px;
                height: 100%;
                background-color: transparent;
                transform: translate(0);
                transform-style: preserve-3d;
                height: auto;
                z-index: 1;
                position: relative;
                border-radius: calc(var(--border-radius) * 2);
                border: 1px solid transparent;
            }
            :global(.roadmap.dark .roadmap__card.active) {
                border: 1px solid var(--black-light);
                padding: var(--landing-page-gap);
                margin-top: -1.5rem;
            }

            :global(.roadmap.dark .roadmap__card.active) {
                display: flex;
                flex-direction: column;
                justify-content: start;
                align-items: start;
                flex: 1 1 calc(25% - 1.5rem);
                min-width: 200px;
                height: 100%;
                padding: 2rem 2rem 3rem 2rem;
                background-color: var(--text);
                transform: translate(0);
                transform-style: preserve-3d;
                height: auto;
                z-index: 1;
                position: relative;
                border: 1px solid var(--black-light);
            }

            @keyframes backgroundGradient {
                0% {
                    background-position: 0% 50%;
                    transform: scale(1.01);
                }
                100% {
                    background-position: 100% 50%;
                    transform: scale(0.98);
                }
            }

            :global(.roadmap.dark .roadmap__card.active::before) {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: linear-gradient(45deg, var(--pink), var(--teal));
                z-index: -1;
                filter: blur(20px);
                border-radius: calc(var(--border-radius) * 2);
                animation: backgroundGradient 4s ease-in-out infinite alternate;
                background-size: 200% 200%;
            }

            :global(.roadmap.dark .roadmap__card.active::after) {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: var(--covalent-dark-blue);
                z-index: -1;
                border-radius: calc(var(--border-radius) * 2);
                background-image: linear-gradient(135deg,#00d8d521 0%,#ff4c8b33 100%);
                // opacity: 0.85;
            }



            :global(.roadmap.dark .roadmap__description) {
                color: var(--text);
            }
            .roadmap__content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                height: max-content;
                padding: var(--landing-page-padding);
                max-width: var(--landing-page-max-width);
                margin: 0 auto;
                width: 100%;
            }
            @media (max-width: 768px) {
                .roadmap {
                    flex-direction: column;
                    height: auto;
                }
                .roadmap__content {
                    width: 100%;
                    height: auto;
                    padding: var(--landing-page-padding);
                }
                :global(.roadmap.dark .roadmap__cards) {
                    width: 100%;
                    width: calc(100% + 4rem);
                    margin-left: -2rem;
                    margin-right: -2rem;
                    padding-left: 2rem;
                }
            }
        `}
        </style>
    </>;
};

export const RoadMapTitle = ({children}) => {
    return <><h2>
        {children}
    </h2>
    <style jsx>
        {`
            h2 {
                font-size: var(--font-size-7);
                line-height: 1.2;
            }
        `}
    </style>
    </>;
};

export const RoadMapLine = () => {
    return <><div className="roadmap__line"/>
        <style jsx>
            {`
            .roadmap__line {
                border-top: 1px solid var(--black-light);
                border-left: 300px solid transparent;
                border-right: 300px solid transparent;
                width: 100%;
                position: absolute;
                bottom: 1.5rem;
                filter: drop-shadow(0px 0px 5px #fff);
            }
        `}
        </style>
    </>;
};

export const RoadMapCard = ({children, active = false}) => {
    return <><div className={"roadmap__card " + (active && "active")}>
        {children}
        {active && <div className="dot-wrapper"><div className="dot"/></div>}
    </div>
    <style jsx>
        {`
            .roadmap__card {
                display: flex;
                flex-direction: column;
                justify-content: start;
                align-items: start;
                width: 100%;
                gap: var(--landing-page-gap);
                height: auto;
                position: relative;
                background: #fff;
                cursor: default;
                position: relative;
                margin-top: 0.5rem;
            }

            .dot {
                width: 1rem;
                height: 1rem;
                border-radius: 50%;
                background-color: var(--teal);
                margin: 0 auto;
            }

            .dot-wrapper {
                position: absolute;
                bottom: -4rem;
                width: 100%;
                left: 0;
            }
                

            hr {
                left: calc(-0.75rem - 1px);
                right: calc(-0.75rem - 1px);
                margin-top: var(--landing-page-gap);
                position: absolute;
                bottom: -1.5rem;
            }

            .active.roadmap__card hr {
                // bottom: calc(-1rem - 1px);
                // left: calc(-0.75rem - 1px);
                // right: calc(-0.75rem - 1px);
            }
            @media (max-width: 768px) {
                div {
                    flex-direction: column;
                    place-items: start;
                }
            }
        `}
    </style></>;
};


export const RoadMapCards = ({children}) => {
    return <><div className="roadmap__cards">
        {children}
    </div>
    <style jsx>
        {`
            div {
                display: flex;  
                flex-direction: row;
                justify-content: start;
                gap: var(--landing-page-gap);
               
            }
            @media (max-width: 768px) {
                div {
                    // flex-direction: column;
                    // place-items: start;
                }
            }
        `}
    </style></>;
};

export const RoadMapCardCTA = ({children}) => {
    return <><div>
        {children}
    </div>
    <style jsx>
        {`
            div {
                margin-top: auto;
            }
        `}
    </style></>;
};

export const RoadMapCardTitle = ({children}) => {
    return <><h2>
        {children}
    </h2>
    <style jsx>
        {`
            div {
                font-size: var(--font-size-6);
                color: var(--pink);
            }
            h2 {
                font-size: var(--font-size-6);
                margin-bottom: 1rem;
                font-weight: 300;
                margin-top: 0;
                margin: 0;
                line-height: 1.2;
            }
        `}
    </style></>;
};

export const RoadMapCardDescription = ({children}) => {
    return <><p className="roadmap__description">
        {children}
    </p>
    <style jsx>
        {`
            p {
                color: var(--black-light);
            }
        `}
    </style></>;
};

export const RoadMapCardSubtitle = ({children}) => {
    return <>
        <p>
            {children}
        </p>
        <style jsx>
            {`
            p {
                font-size: var(--font-size-3);
                color: var(--teal);
                margin-bottom: 0;
                padding-bottom: 0;
            }
        `}
        </style>
    </>;
};

export const RoadMapCardImage = ({src}) => {
    return <><div className="image">
        <Image data={src}  usePlaceholder={false}/>
    </div>
    <style jsx>
        {`
           .image {
                max-width: 8rem;
           }
        `}
    </style></>;
};